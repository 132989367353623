.wrapper-messages {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.wrapper-messages-content {
    width: 100%;
}

.list_container {
    display: flex;
    flex-wrap: wrap;
}

.list_container li {
    min-width: 485px;
    max-width: 485px;
}

.list_container li:nth-child(2n) {
    margin-left: 20px;
}

.messages-tabs-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.wrapper-messages-right {
    width: 607px;
    height: calc(100vh - 160px);
    margin-left: 20px;
}

.messages-tabs {
    display: flex;
    margin-bottom: 23px;
}

.messages-tab {
    display: flex;
    align-items: center;
    height: 28px;
    padding: 0 10px;
    border-radius: 14px;
    font-weight: 600;
    color: #4886f1;
    cursor: pointer;
}

.messages-tab.active {
    color: #234c7d;
    background-color: #d9e5ec;
}

.messages-tab-content {
    flex: 1 1;
    overflow-y: auto;
}

.messages-area {
    border-radius: 17px;
    overflow: hidden;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.messages-area__head {
    height: 140px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background: #c3d2db;
    padding: 20px;
}

.messages-area__container {
    width: 100%;
    min-height: 400px;
    flex: 1 1;
    overflow-y: auto;
    padding: 0 20px 20px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-start;
}

.messages-area__container.loading {
    flex: 1 1;
    padding: 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.messages-area__form {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.chat {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    align-items: center;
}

.chat_title .container {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.chat_photo {
    width: 100px;
    height: 100px;
}

.chat_popular {
    position: absolute;
    left: 3px;
    top: 5px;
}

.messages-area__default-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.message_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 70px;
    padding: 10px 0 40px;
}

.message_box_text {
    text-align: center;
    margin-bottom: 10px;
    color: #8e9295;
}

.message_input {
    display: flex;
    justify-content: space-between;
}

.messages-form-input {
    justify-content: space-between;
    padding: 9px 15px;
    height: auto;
    min-height: 50px;
}

.message_textarea {
    border: none;
    background-color: transparent;
    resize: none;
    font-family: 'SF Pro Text', -apple-system, sans-serif;
    font-size: 16px;
    width: 100%;
}

.send_message {
    border: none;
    background-color: #4886f1;
    width: 30px;
    height: 30px;
    outline: none;
    cursor: pointer;
}

.chat_ask {
    color: #8e9295;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 10px;
}

.chat_ask_text {
    width: 100%;
    max-width: 343px;
    margin: 40px auto 0;
}

.chat_ask li {
    padding: 8px 15px;
    border-radius: 20px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
}

.chat_ask li:nth-child(2n-1) {
    margin-right: 5px;
}

.chat_ask ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 300px;
    margin: 10px 0 20px;
}


.messages_wrap {
    display: flex;

}

.messages_separator {
    min-height: 18px;
}

.message_translate {
    padding: 0 15px;
    color: var(--light-color-text);
}

.message_translate_action {
    color: var(--primary-color-active);
    font-weight: 600;
    padding: 0 15px;
    cursor: pointer;
}

.messages_list {
    display: inline-block;
    flex-direction: column;
    margin-top: 2px;
    position: relative;
}

.messages_list.to svg {
    position: absolute;
    right: -6px;
    bottom: 0;
}

.messages_list.from svg {
    position: absolute;
    left: -6px;
    bottom: 0;
}

.messages_list.from {
    margin-right: auto;
}


.messages_list.to {
    margin-left: auto;
}

.message_wrap {
    margin-bottom: 2px;
}

.message_wrap.info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.icon_info {
    width: 24px;
    height: 24px;
    margin-left: 5px;
    position: relative;
    z-index: 1;
}

.message_text {
    display: flex;
    border-radius: 16px;
    padding: 7px 15px;
    max-width: 215px;
    width: auto;
}

.message_text.unpaid span {
    filter: blur(3px);
}

.message_text.from {
    background-color: #e9f1f6;
}

.message_text.to {
    background-color: #4886f1;
    color: #ffffff;
}

.message_text.error {
    background-color: #f78888;
    color: #ffffff;
    opacity: .5;
}

.message_text.last_message {
    position: relative;
}

.message_text.last_message:before, .message_text.last_message:after {
    content: "";
    position: absolute;
    bottom: -1px;
    height: 18px;
}

.message_text.last_message.from:before {
    left: -6px;
    border-left: 20px solid #e9f1f6;
    border-bottom-right-radius: 18px 18px;
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
}

.message_text.last_message.from:after {
    left: 15px;
    width: 15px;
    background: #fff;
    border-bottom-right-radius: 8px;
    -webkit-transform: translate(-30px, -3px);
    transform: translate(-30px, -3px);
    height: 20px;
}

.message_text.last_message.error:before,
.message_text.last_message.to:before {
    right: -6px;
    border-bottom-left-radius: 18px 18px;
    -webkit-transform: translate(0, -3px);
    transform: translate(0, -3px);
}

.message_text.last_message.to:before {
    border-right: 20px solid #4886f1;
}

.message_text.last_message.error:before {
    border-right: 20px solid #f78888;
}

.message_text.last_message.error:after,
.message_text.last_message.to:after {
    right: -45px;
    left: auto;
    width: 15px;
    background: #fff;
    border-bottom-left-radius: 8px;
    -webkit-transform: translate(-30px, -3px);
    transform: translate(-30px, -3px);
    z-index: 0;
    height: 20px;
}

.translation_text {
    padding: 0 15px;
    color: #8e9295;
}

.btn_translate {
    padding: 0 15px;
}

.message_text_last {
    display: flex;

}

.translation_text {
    display: flex;

}

.btn_more {
    margin-top: 10px;
}


@media screen and (max-width: 1020px) {
    .list_container li:nth-child(2n) {
        margin-left: 0px;
    }
    .list_container li {
        min-width: 100%;
        max-width: 100%;
    }
    .messages-area__container {
        background-color: white;
        min-height: 100px;
        transition: all 0.2s ease 0s;
    }
}