.server-error {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(36, 37, 38, 0.7);
  z-index: 9999;

  &__container {
    display: block;
    max-width: 425px;
    background-color: #fff;
    border-radius: 17px;
    padding: 60px 40px;
    position: relative;
    text-align: center;
  }

  &__title {
    margin: 25px 0 40px;
    font-size: 20px;
    font-weight: 900;
  }

  &__text {
    font-size: 16px;
    margin-bottom: 50px;
  }

  &__cancel {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 18px;
    height: 18px;
    fill: #c3d2db;
    cursor: pointer;
  }
}