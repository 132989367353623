.carousel-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.carousel  {
  display: flex;
  height: 100%;
  transition: all 0.5s ease;
}

.carousel-arrow {
  position: absolute;
  display: block;
  background: rgb(195, 210, 219);
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  margin-right: 5px;
}

.carousel-arrow-next, .carousel-arrow-prev {
  opacity: .5;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  bottom: 15px;
  border: none;
  outline: none;
  line-height: 0;
  outline: 0;
  z-index: 9
}

.carousel-arrow-next:focus, .carousel-arrow-next:hover, .carousel-arrow-prev:focus, .carousel-arrow-prev:hover {
  outline: 0;
  opacity: .7
}

.carousel-arrow-prev.carousel-arrow-disabled, .carousel-arrow-next.carousel-arrow-disabled {
  opacity: .35
}

.carousel-arrow-next:before, .carousel-arrow-prev {
  left: 20px
}

.carousel-arrow-prev:before {
  content: url('/img/prev-arrow.svg')
}

.carousel-arrow-next:before {
  content: url('/img/next-arrow.svg');
}

.carousel-arrow-prev:before, .carousel-arrow-next:before {
  display: block;
  width: 20px;
  height: 26px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.carousel-arrow-next {
  right: 20px
}

.ll-gallery .carousel-arrow-next,
.ll-gallery .carousel-arrow-prev {
  height: 100%;
  top: 0;
  background: rgba(255, 255, 255, 0.8) !important;
  border-radius: 0px;
}
.ll-gallery .carousel-arrow-next {
  right: 0;
}
.ll-gallery .carousel-arrow-next:before,
.ll-gallery [dir='rtl'] .carousel-arrow-prev:before {
  content: url(/img/ll-next-arrow.svg);
}
.ll-gallery .carousel-arrow-prev:before,
.ll-gallery [dir='rtl'] .carousel-arrow-next:before {
  content: url(/img/ll-next-arrow.svg);
  transform: rotate(180deg);
}

.ll-gallery .carousel-arrow-prev {
  left: 0;
}

.ll-gallery .carousel-arrow-prev::before,
.ll-gallery [dir='rtl'] .carousel-arrow-next::before {
  left: calc(50% - 10px);
  top: calc(50% - 13px);
}.gallery_photo {
   position: absolute;
   z-index: 999;
   width: 100%;
   max-width: 800px;
   height: 650px;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   overflow: hidden;
   display: flex;
   align-items: center;
   justify-content: center;

   &__image {
     position: relative;
     width: auto;
     height: 100%;
     max-height: 650px;
   }

   &__container {
     position: relative;
     display: flex;
     justify-content: center;
   }

   &__actions {
     width: 100%;
     position: absolute;
     bottom: 25px;
     display: flex;
     justify-content: center;
   }

   &__actions-edit {
     flex-direction: column;
     align-items: flex-start;
     max-width: 800px;
     background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 50%, transparent);
     bottom: 0;
     padding-bottom: 20px;

     .btn {
       margin-top: 10px;
     }
   }

   &__arrow {
     width: 60px;
     height: 100px;
     border-radius: 12px;
     background-color: rgba(255, 255, 255, 0.2);
     position: absolute;
     top: 50%;
     transform: translateY(-50%);
     z-index: 9999;
     display: flex;
     align-items: center;
     justify-content: center;
     cursor: pointer;
     opacity: 0.5;
     transition: opacity 0.3s ease;

     &:hover {
       opacity: 1;
     }

     &.next {
       margin-right: -480px;
       right: 50%;
     }

     &.prev {
       left: 50%;
       margin-left: -480px;
     }
   }

   &__count {
     top: 50%;
     right: 50%;
     margin-top: -360px;
     text-align: center;
     z-index: 9999;
     color: #ffffff;
     position: absolute;
     margin-right: -100px;
     width: 200px;
   }

   &__close {
     background: rgba(255, 255, 255, 0.2);
     opacity: 0.5;
     transition: opacity 0.3s ease;
     z-index: 9999;
     position: absolute;
     width: 32px;
     height: 32px;
     display: flex;
     align-items: center;
     justify-content: center;
     border-radius: 50%;
     top: 50%;
     transform: translate(-50%, -50%);
     right: 50%;
     margin-right: -480px;
     margin-top: -360px;
     cursor: pointer;

     &:hover {
       opacity: 1;
     }
   }
 }

@media screen and (max-width: 1020px) {
  .gallery_photo {
    z-index: 999;
    width: 95%;
    max-width: 95%;
    height: auto;
    position: fixed;

    &__image {
      max-height: 95vh;
    }

    &__close {
      top: 25px;
      transform: none;
      right: 25px;
      margin-right: 0;
      margin-top: 0;
      fill: white;
      background: rgba(17, 0, 255, 0.4);
      opacity: 1;
    }

    &__arrow {
      position: fixed;
      top: 35%;
      background-color: rgba(255, 255, 255, 0.4);
      transform: none;
      cursor: pointer;
      opacity: 1;

      &.next {
        margin-right: 0;
        right: 10px;
      }

      &.prev {
        left: 10px;
        margin-left: 0;
      }
    }
  }
}
