.Backdrop {
    z-index: 50;
    background: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
@media screen and (max-width: 1020px) {
    .Backdrop {
        z-index: 7;
    }
}
