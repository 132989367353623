.Loader {
    display: block;
    position: relative;
    width: 40px;
    height: 40px;
    margin: 20px auto;
}
.Loader div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 8px;
    border: 3px solid var(--loader-color);
    border-radius: 50%;
    animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--loader-color) transparent transparent transparent;
}
.Loader div:nth-child(1) {
    animation-delay: -0.45s;
}
.Loader div:nth-child(2) {
    animation-delay: -0.3s;
}
.Loader div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
