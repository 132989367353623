@media screen and (max-width: 1020px) {
    .container {
        max-width: 420px;
    }

    .card {
        margin-left: 0;
    }

    .wrapper_cards {
        margin-left: 0;
    }

    .container-with-filter {
        flex-direction: column;
        align-items: flex-start;
    }

    .container-with-filter .btn_filter {
        margin-top: 10px;
    }

    .my-profile__coins {
        position: absolute;
        right: 20px;
        top: 20px;
        color: #fff !important;
        font-weight: bold;
        z-index: 999;
    }

    .bottom-menu-wrap {
        background-color: white;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        z-index: 10;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .bottom-menu__item span.active,
    .bottom-menu__item.active span.no-active {
        display: none;
    }

    .bottom-menu__item.active span.active,
    .bottom-menu__item span.no-active {
        display: flex;
    }

    .bottom-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        list-style: none;
    }

    .bottom-menu__item {
        fill: #6e7478;
        color: #6e7478;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-decoration: none;
        position: relative;
        cursor: pointer;
    }

    .bottom-menu__item span.active,
    .bottom-menu__item.active span.no-active {
        display: none;
    }

    .bottom-menu__item.disable span.active {
        display: none !important;
    }

    .bottom-menu__item.disable span.no-active {
        display: flex !important;
    }

    .bottom-menu__item.active span.active,
    .bottom-menu__item span.no-active {
        display: flex;
    }

    .bottom-menu__item.active {
        fill: #0e4d80;
        color: #0e4d80;
        display: flex;
        align-items: center;
    }

    .bottom-menu__text {
        margin-top: 2px;
        font-size: 11px;
    }

    .title-text {
        font-size: 35px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 10px;
    }

    .title-text_notifications {
        padding-top: 30px;
        padding-left: 20px;
        margin: 10px auto;
        width: 375px;
    }

    .notifications__list .dialog {
        padding: 0 20px 0 20px;
        margin-right: 0 auto;
        width: 375px;
    }

    .card {
        min-width: 100%;
        max-width: 420px;
        width: 100%;
    }

    .popup_body {
        width: 100%;
        position: fixed;
        min-width: 100%;
        max-width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0;
        max-height: none;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: none;
        background-color: #e9f1f6;
        overflow: hidden;
        border-radius: 0px;
    }

    .popup_body__container {
        width: 100%;
    }

    .popup_content__chat {
        flex: 1;
    }

    .notifications {
        width: 100%;
        height: 100vh;
        border-radius: 0;
        box-shadow: none;
        background-color: #e9f1f6;
        z-index: 9;
        position: fixed;
        top: 0;
        left: 0;
        margin-left: 0;
        padding: 0;
    }

    .popup {
        padding: 60px 40px;
        border-radius: 0px;
        width: 100%;
        max-width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .popup-sign-in {
        position: fixed;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transform: none;
    }

    .popup_close {
        position: absolute;
        right: 20px;
        top: 20px;
        display: flex;
        width: 20px;
        height: 20px;
        cursor: pointer;
        transition: 0.3s ease;
        fill: #c3d2db;
    }

    .step_form {
        padding: 45px 14px 55px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }

    .step_form .left_wrap {
        margin-right: 110px;
        margin-bottom: 10px;
    }

    .step_form .left_wrap,
    .step_form .right_wrap {
        width: 100%;
        max-width: 345px;
    }

    .mobile-scroll {
        overflow: auto !important;
    }

    .fixed-mobile-footer {
        position: fixed !important;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .flex-1 {
        flex: 1;
    }

    .body-overflow-hidden {
        overflow: hidden;
    }

    .mobile-pt-100 {
        padding-top: 160px;
    }

    .mobile-pb-50 {
        padding-bottom: 50px;
    }

    .mobile-mt-n3 {
        margin-top: -3px !important;
    }

    .mobile_report {
        text-align: center;
        position: absolute;
        right: 30px;
        bottom: 140px;
    }

    .mobile-mt-40 {
        margin-top: 40px;
    }

    .keyboard {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9990;
    }

    .profile_gallery_item {
        height: 320px;
        margin-right: 0;
    }

    .bg_down {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.3)
        );
        z-index: 999;
    }

    .bg_up {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(
                to top,
                rgba(0, 0, 0, 0),
                rgba(0, 0, 0, 0.3)
        );
        /*z-index: 999;*/
    }

    .profile_gallery_wrap {
        min-height: 320px;
        height: 320px;
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .popup_content.scroll {
        border-bottom: none;
        border-top: none;
    }

    .location_text_wrap {
        position: absolute;
        left: 20px;
        bottom: 20px;
        z-index: 2;
    }

    .location_text {
        font-size: 24px;
        color: white;
        text-align: left;
        font-weight: 900;
        line-height: 30px;
    }

    .report_section {
        position: absolute;
        right: 0px;
        bottom: 0px;
        padding: 20px;
        z-index: 2;
    }

    .report_section .report_block {
        top: auto;
        bottom: 20px;
        width: max-content;
    }

    .report_section .report_type.report_block {
        top: -200px;
    }

    .photo_footer {
        width: 100%;
        height: 100%;
        position: relative;
        margin-top: -100%;
        z-index: -10;
    }

    .profile_wrapper {
        padding: 15px 20px;
    }

    .occupation__text {
        font-size: 18px;
        color: #242526;
    }

    .about_mobile {
        padding: 10px;
        background-color: #f5f6e9;
        font-size: 18px;
        border-radius: 12px;
        margin-top: 20px;
    }

    .activity_wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .activity_title {
        margin-top: 20px;
        color: #242526;
        font-size: 18px;
        font-weight: 600;
    }

    .activity_item {
        font-weight: 100;
        margin-top: 7px;
        color: #242526;
        font-size: 18px;
        background-color: #f3faff;
        border-radius: 20px;
        padding: 9px 15px;
    }

    .profile_row {
        display: flex;
        flex-direction: column;
        margin-bottom: 0px;
    }

    .profile_row__title {
        min-width: 110px;
        margin-right: 20px;
        font-weight: 600;
        text-align: left;
        font-size: 18px;
        margin-top: 20px;
    }

    .row-action {
        margin-top: 10px;
    }

    .profile_row__text {
        text-align: left;
        font-size: 18px;
        margin-top: 10px;
    }

    .mobile_profile_footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        bottom: 0;
        padding: 0 20px;
        left: 0;
        width: 100%;
        min-height: 110px;
        background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.9) 67%
        );
    }

    .mobile_profile_footer_height {
        min-height: 110px;
        width: 100%;
    }

    .mobile_btn_favorites {
        width: auto;
        height: 50px;
    }

    .mobile_btn_chat {
        margin: 0px 20px 0px 0px !important;
        width: 100% !important;
    }

    .bottom_photo_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .bottom_photo_item {
        width: 100%;
        height: 320px;
        overflow: hidden;
        border-radius: 17px;
        margin-top: 20px;
        position: relative;
    }

    .mobile_btn_close {
        position: absolute;
        top: 20px;
        left: 20px;
        border-radius: 30px;
        background-color: #a9b7cc;
        width: 32px !important;
        height: 32px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        z-index: 3;
    }

    .my-profile__actions-menu {
        bottom: 40px !important;
        right: 40px !important;
    }

    .mobile_profile_footer_btns {
        height: 50px !important;
        margin-right: 20px;
    }

    .mobile_upload {
        width: 100%;
        height: 100%;
        background-color: #c3d2db;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 320px;
    }

    .mobile_upload_circle {
        width: 110px;
        height: 110px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #4886f1;
        border-radius: 55px;
    }

    .mobile_upload_bottom {
        border-radius: 17px;
        margin-top: 20px;
    }

    .mobile_step_title {
        font-size: 30px !important;
    }

    .mobile_step_text {
        font-size: 18px !important;
    }

    .premium_coins_wrapper {
        position: absolute;
        width: auto;
        right: 20px;
        top: 20px;
    }

    .premium_coins_wrapper .premium_indicator {
        position: relative;
        left: 0;
        right: auto;
        top: 0;
    }

    .premium_coins_wrapper .my-profile__coins {
        position: relative;
        top: 10px;
        z-index: 9999;
    }
}
