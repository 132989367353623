@charset 'UTF-8';
.slick-next, .slick-prev {
    position: absolute;
    display: block;
    padding: 0
}

.slick-next:before, .slick-prev:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-slider, .slick-slide {
    height: 244px;
}

.slick-slide:focus {
    outline: none;
}

.slick-next, .slick-prev {
    opacity: .5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    bottom: 15px;
    border: none;
    outline: none;
    line-height: 0;
    outline: 0;
    background: 0 0;
    z-index: 9
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    opacity: .7
}

.slick-prev.slick-disabled, .slick-next.slick-disabled {
    opacity: .35
}

.slick-next:before, .slick-prev {
    left: 20px
}

[dir=rtl] .slick-prev {
    right: 20px;
    left: auto
}

.slick-prev:before {
    content: url('/img/prev-arrow.svg')
}

.slick-next:before, [dir=rtl] .slick-prev:before {
    content: url('/img/next-arrow.svg');
}

.slick-prev:before, .slick-next:before,
[dir=rtl] .slick-prev:before, [dir=rtl] .slick-next:before {
    display: block;
    width: 20px;
    height: 26px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.slick-next {
    right: 20px
}

[dir=rtl] .slick-next {
    right: auto;
    left: 20px
}

[dir=rtl] .slick-next:before {
    content: url('/img/next-arrow.svg')
}
